import React, { Component } from 'react';
import './App.css';
import Login from './Login.js';
import DeviceList from './DeviceList.js';
import MissionList from './MissionList.js';
import Account from './Account.js';
import NotificationSystem from 'react-notification-system';

class App extends Component {
    notificationSystem = React.createRef();

    addNotification = (title,message,level) => {
	//event.preventDefault();
	const notification = this.notificationSystem.current;
	notification.addNotification({
	    title: title,
	    message: message,
	    level: level
	});
    };
    
    constructor(props) {
	super(props);

	this.state = {
	    isAuthenticated: false,
	    awsIdentityId: null,
	    awsAccessKeyId: null,
	    awsSecretAccessKey: null,
	    awsSessionToken: null
	};
    }

    setAwsCredentials = (a,b,c,d)  => {
	this.setState({
	    awsIdentityId: a,
	    awsAccessKeyId: b,
	    awsSecretAccessKey: c,
	    awsSessionToken: d
	});
    }
    
    userHasAuthenticated = authenticated => {
	this.setState({ isAuthenticated: authenticated });
    }

    render() {
	const props = {
	    userHasAuthenticated: this.userHasAuthenticated,
	    setAwsCredentials: this.setAwsCredentials
	};

	const dlprops = {
	    addNotification: this.addNotification
	};

	if (!this.state.isAuthenticated) {
	    return (
		    <Login props={props} />
	    );
	}
	else {
	    const style = {
		//"padding-top": "5px",
		//"padding-right": "20px",
		//"padding-bottom": "0px",
		//"padding-left": "5px",
	    };
	    //		    <button onClick={this.addNotification}>Add notification</button>

	    return (
		    <div style={style}>
		    <NotificationSystem ref={this.notificationSystem} />
		    <img width="240" src="icons/droniq_logo.png"/>
		    <Account props={props} />
		    <DeviceList props={dlprops}/>
		    </div>
		    
	    );
	}
    }
}

export default App;
