/*global Janus*/
/*global SigV4Utils*/
/*global Paho*/
/*global BrowserFS*/
/*global mavlink*/

import React, { Component } from "react";
import AWS from 'aws-sdk';
import { Rnd } from "react-rnd";
import GamePad from './GamePad.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeviceControl from './DeviceControl.js';
import WebRtcStats from './WebRtcStats.js';
import DeviceConfig from './DeviceConfig.js';
import StatusBar from './StatusBar.js';
import ActionBar from './ActionBar.js';
import RealtimeMap from './RealtimeMap.js';

//import Janus from './janus/janus.js';

export default class Device extends Component {
    constructor(props) {
	super(props);
	this.state = {
	    receivingvideo: false,
	    connectionState: '',
	    cancelConnect: false,
	    disconnected: true,
	    gimbal_control_image: "icons/gimbal_control.png",
	    gimbal_control_x_axis: 0,
	    gimbal_control_y_axis: 0,
	    gimbal_web_control_x_axis: 0,
	    gimbal_web_control_y_axis: 0,
	    gimbal_global_x: 0,
	    gimbal_global_y: 0,
	    gimbal_global_x_cache: 0,
	    gimbal_global_y_cache: 0,
	    gamepad_button0_pressed: false,
	    gamepad_button0_pressed_cache: false,
	    images_captured: "-",
	    gcs_lat: 0,
	    gcs_lon: 0,
	    device_lat: 0,
	    device_lon: 0,
	    device_alt: 0,
	    device_bat: -1,
	    camerastream: 11, // FIXME: only for initial selection; later we ONLY use it in DeviceControl
	    signalQuality: 0,
	    accessTechnologies: 0,
	    streams : []
	};

	
	this.handleStatusUpdates();

	this.myPeerConnection = null;
	
	this.last_close_trigger = 0;
	
	this.cellularDataAge = 0;
	setInterval(function(){
	    this.cellularDataAge+=1;
	    if (this.cellularDataAge>3) {
		this.setState({
		    signalQuality: 0,
		    accessTechnologies: 0
		});
	    }
	}.bind(this),1000)
    }

    handleStatusUpdates() {
	var requestUrl = SigV4Utils.getSignedUrl('wss', 'ae41f5w1njy9h-ats.iot.us-west-2.amazonaws.com', '/mqtt', 'iotdevicegateway', 'us-west-2',AWS.config.credentials.accessKeyId, AWS.config.credentials.secretAccessKey, AWS.config.credentials.sessionToken);
	console.log(requestUrl);

	var clientId = Math.random().toString(36).substring(7);
	var client = new Paho.MQTT.Client(requestUrl, clientId);
	var connectOptions = {
	    onSuccess: function () {
		console.log('connected');
		client.subscribe("cc/"+this.props.device+ "/debug/fromdevice/");
		client.subscribe("cc/"+this.props.device+ "/events/");
		console.log("SUBSCRIBED");
	    }.bind(this),
	    useSSL: true,
	    timeout: 15,
	    mqttVersion: 4,
	    onFailure: function (err) {
		console.error('connect failed' + err.errorMessage);
	    }
	};

	client.onMessageArrived = onMessageArrived.bind(this);
	
	client.connect(connectOptions);
	client.onConnectionLost = (res) => {
	    console.log("Connection Lost"); // WHY? RECONNECT !?
	}
	    

	function onMessageArrived(message) {
	    var msg = JSON.parse(message.payloadString);
	    //console.log(message);
	    //console.log(msg);
	    if (message.destinationName.endsWith('/events/')) {
		console.log(msg.event.bucket);
		console.log(msg.event.key);
	    }
	    else {
		
		//console.log(message.payloadString);
		this.setState({
		    "signalQuality": msg.SignalQuality,
		    "accessTechnologies": msg.AccessTechnologies
		});
		this.cellularDataAge = 0;
	    }
	}
    }

    

    distance(lat1, lon1, lat2, lon2, unit) {
	if ((lat1 == lat2) && (lon1 == lon2)) {
	    return 0;
	}
	else {
	    var radlat1 = Math.PI * lat1/180;
	    var radlat2 = Math.PI * lat2/180;
	    var theta = lon1-lon2;
	    var radtheta = Math.PI * theta/180;
	    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	    if (dist > 1) {
		dist = 1;
	    }
	    dist = Math.acos(dist);
	    dist = dist * 180/Math.PI;
	    dist = dist * 60 * 1.1515;
	    if (unit=="K") { dist = dist * 1.609344 }
	    if (unit=="N") { dist = dist * 0.8684 }
	    return dist;
	}
    }

    componentDidMount() {
	this.videoRef = React.createRef();
	this.sendChannel = null;
	this.datawebsocket = null;
	this.connectionAttempt = 1;
	this.session = null;
	this.connection = null;
	
	if (this.props.data.firmware.flavor==='main' || this.props.data.firmware.flavor==='link' || this.props.data.firmware.flavor==='link4k'  || this.props.data.firmware.flavor==='link3') {
	    this.setState({ camerastream: 10});
	}

	//TODO FIXME: only establish control connection to device that is 'connected'
	this.deviceControlConnection();

	BrowserFS.install(window);
	fetch('./mav.zip').then(function(response) {
	    return response.arrayBuffer();
	}).then(function(zipData) {
	    var Buffer = BrowserFS.BFSRequire('buffer').Buffer;

	    BrowserFS.configure({
		fs: "MountableFileSystem",
		options: {
		    "/zip": {
			fs: "ZipFS",
			options: {
			    zipData: Buffer.from(zipData)
			}
		    }
		}
	    }, function(e) {
		if (e) {
		    throw e;
		}


		this.myMAV = new mavlink(1,47); 
		this.myMAV.on("ready", function() {
		    console.log("ready"); // for some strange reason this does not seem to trigger...

		});
		this.myMAV.on("FLIGHT_INFORMATION", function(message, fields) {
		    console.log(fields);
		});

		this.myMAV.on("SYS_STATUS", function(message, fields) {
		    var battery = fields.battery_remaining;
		    this.setState({
			'device_bat': battery
		    });
		    }.bind(this));
		
		this.myMAV.on("GLOBAL_POSITION_INT", function(message, fields) {
		    var device_lat = fields.lat/10000000;
		    var device_lon = fields.lon/10000000;
		    var device_alt = fields.relative_alt/1000;
		    this.setState({
			'device_lat': device_lat,
			'device_lon': device_lon,
			'device_alt': device_alt
		    });

		}.bind(this));

		this.myMAV.on("CAMERA_IMAGE_CAPTURED", function(message, fields) {
		    console.log(fields);
		    this.setState({
			images_captured: message.image_index
		    });
		});

		this.myMAV.on("sequenceError", function(message) {
		    //console.log(message);
		    console.log("SEQ ERR");
		});
		this.myMAV.on("checksumFail", function(msg_id, msgxmlsum, calcsum, recsum) {
		    //b0b0: TODO - those are probably not yet in the XML file
		    //console.log("CHK ERR: " + msg_id);
		});


		this.myMAV.on("message", function(message) {
		    //console.log("GOT MSG");
		    //console.log(message);

		    //GLOBAL_POSITION_INT ( #33 )
		    //console.log(message.id);
		    //if (message.id<256) {
			//console.log("LOW ID");
		//}
		    //else {
		//	console.log("HIGH ID");
		//	console.log(message);
		//}
			   
		  //  if (message.id==33) {
		//	console.log("GLOBAL_POSITION_INT ( #33 )");
		    //console.log(message)
		//}
		  //  else if (message.id==32) {
			//console.log("LOCAL_POSITION_NED");
		    //}
		    //else {
			//console.log("ID: " + message.id)
		//}
		});
		
	    }.bind(this));
	}.bind(this));

	this.GIMBAL_CONTROL_LOOP_FREQUENCY = 100; //100hz
	setInterval(this.gimbal_control_loop.bind(this),Math.floor(1000/this.GIMBAL_CONTROL_LOOP_FREQUENCY));
	setInterval(this.send_heartbeat.bind(this),1000);

	if ("geolocation" in navigator) {
	    navigator.geolocation.getCurrentPosition(function(position) {
		console.log(position.coords.latitude, position.coords.longitude);
		this.setState({
		    gcs_lat: position.coords.latitude,
		    gcs_lon: position.coords.longitude
		});
	    }.bind(this));
	}
	
    }

    gimbal_control_loop() {
	if (!this.state.disconnected) {
	    // IN: gamebad x_axis y_axis
	    // OUT: x_offset y_offset
	    var x_offset = 0;
	    var y_offset = 0;
	    var x_offset_web = 0;
	    var y_offset_web = 0;
	    
	    var MAX_SPEED=90; //90 degree per second

	    x_offset = this.state.gimbal_control_x_axis*MAX_SPEED/this.GIMBAL_CONTROL_LOOP_FREQUENCY;
	    y_offset = -this.state.gimbal_control_y_axis*MAX_SPEED/this.GIMBAL_CONTROL_LOOP_FREQUENCY;

	    x_offset_web = this.state.gimbal_web_control_x_axis*MAX_SPEED/this.GIMBAL_CONTROL_LOOP_FREQUENCY;
	    y_offset_web = -this.state.gimbal_web_control_y_axis*MAX_SPEED/this.GIMBAL_CONTROL_LOOP_FREQUENCY;

	    
	    // force min/max bounds:
	    var x_new = this.state.gimbal_global_x+x_offset+x_offset_web;
	    var y_new = this.state.gimbal_global_y+y_offset+y_offset_web;

	    if (x_new<-180) { x_new = 0-x_new }
	    if (x_new>360)  { x_new = 360-x_new }
	    if (y_new<-90) { y_new = -90 }
	    if (y_new>90)  { y_new = 90 }	    
	    
	    this.setState({
		gimbal_global_x: x_new,
		gimbal_global_y: y_new
	    });

	    //console.log("GlobalX="+this.state.gimbal_global_x+" GlobalY="+this.state.gimbal_global_y);
	    //console.log("Gimbal Loop: x=" + this.state.gimbal_control_x_axis + " y=" + this.state.gimbal_control_y_axis + " x_offset="+x_offset+" y_offset="+y_offset);

	    if (this.state.gimbal_global_y==this.state.gimbal_global_y_cache && this.state.gimbal_global_x==this.state.gimbal_global_x_cache) {
		//console.log("same same");
	    }
	    else {
		this.cmd_gimbal_control(this.state.gimbal_global_y,this.state.gimbal_global_x);
	    }


	    this.setState({
		gimbal_global_x_cache: x_new,
		gimbal_global_y_cache: y_new
	    });

	    
	    // TODO implement generalized array accross all buttons https://developer.mozilla.org/en-US/docs/Games/Techniques/Controls_Gamepad_API
	    if (this.state.gamepad_button0_pressed) {
		if (!this.state.gamepad_button0_pressed_cache) {
		    this.trigger_camera();
		    this.setState({
			gamepad_button0_pressed_cache: true
		    });
		}
	    }
	    else {
		this.setState({
		    gamepad_button0_pressed_cache: false
		});
	    }
    
	}
    }


    connect = (device) => {
	this.setState({
	    cancelConnect : false
	});
	    var requestUrl = SigV4Utils.getSignedUrl('wss', 'ae41f5w1njy9h-ats.iot.us-west-2.amazonaws.com', '/mqtt', 'iotdevicegateway', 'us-west-2',AWS.config.credentials.accessKeyId, AWS.config.credentials.secretAccessKey, AWS.config.credentials.sessionToken);

	    console.log(device);


	    try {
		if (typeof global != 'undefined')  {
		//console.log(global);
		
		var wsport = global.location.search.substring(4);
		console.log("Using WS: " + wsport);
		
		this.datawebsocket = new WebSocket("ws://127.0.0.1:" + wsport, "skydronedata");

		this.datawebsocket.onopen = function (event) {
		    console.log('ws open');
		}

		this.datawebsocket.onerror = function (event) {
		    console.log('ws error');
		}
		
		this.datawebsocket.onmessage = (event) => {
		    console.log("received and resending: Data size: " + event.data.length);

		    var sendQueue = [];

		    // fixme: double queue
		    const sendMessage = (msg) => {
			switch(this.sendChannel.readyState) {
			case "connecting":
			    console.log("Connection not open; queueing: " + msg);
			    sendQueue.push(msg); // FIXME -> this will never work as the sendeque is cleared on each onmessage function call
			    break;
			case "open":
			    //console.log("BUF: " + sendChannel.bufferedAmount);
			    // Chrome support max 16kb per msg on a datachannel - see https://webrtc.org/web-apis/chrome/

			    function chunkArray(myArray, chunk_size){
				var index = 0;
				var arrayLength = myArray.length;
				var tempArray = [];
				
				for (index = 0; index < arrayLength; index += chunk_size) {
				    var myChunk = myArray.slice(index, index+chunk_size);
				    // Do something if you want with the group
				    tempArray.push(myChunk);
				}

				return tempArray;
			    }

			    var msgarray = chunkArray(msg,16384);
			    //console.log("Chunked into: " + msgarray.length);
			    for (var i=0;i<msgarray.length
				 ;i++) {
				sendQueue.push(msgarray[i]);
				//console.log("pushed.");
			    }
			    //console.log("Queue Size: " + sendQueue.length);
			    sendQueue.forEach((queuedmsg) => {
				//console.log("open->send() " + queuedmsg.length);
				this.sendChannel.send(queuedmsg)
			    }
					     );
			    break;
			case "closing":
			    console.log("Attempted to send message while closing: " + msg);
			    break;
			case "closed":
			    console.log("Error! Attempt to send while connection closed.");
			    break;
			default:
			    console.log("SHIT");
			    break;
			}
		    }
		    
		    //sendChannel.send(event.data);
		    sendMessage(event.data);
		}
	    }
	    else {
		console.log('ws configuration error');
	    }
	}
	catch(e) {
	    console.log("Websockets disabled");
	}

	// b0b0 @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
	
	var janus = new Janus.Client(requestUrl, {
	    deviceid: device,
	    token: 'token',
	    apisecret: 'apisecret',
	    keepalive: true,
	    iceconfig: {
		iceServers: [
		    {
			'url': 'stun:stun.l.google.com:19302'
		    },
		    {
			'url': 'turn:52.57.41.255:3478',
			'credential': 'turn',
			'username': 'skydrone'
		    }
		],
		iceTransports: "all" //to force relay with TURN -> this should be "relay" - otherwise "all"
	    }
	});
	
	janus.createConnection('id')
	    .then(function(myConnection) {
		//console.log('go further from here');
		this.connection = myConnection;
		this.createSession();
	    }.bind(this)).catch(function(e) {
		console.log("ERROR 100" + e);
	    })


    }

    showConnectionState(state) {
	console.log("Connection State: " + state);
    }
 
    cleanupAndReconnect() {
	this.myPeerConnection = null;

	// NOTE: destroy is a command to janus -> id does not help here because it will never reach the server
	// but we need to make sure that the client stops sending keepalive messages!
	//this.session.destroy().then(function() {
	if (this.session) {
	    this.session.cleanup();
	}
	if (!this.state.cancelConnect) {
	    this.connectionAttempt += 1;
	    this.createSession(this.connection);
	}
	else {
	    this.setState({
		cancelConnect : false
	    });
	}
    }
    
    createSession() {
	    this.setState({
		disconnected: false
	    });
	var pc = null;

	    //var session = null;
	
	Promise.try(function() {
	    console.log("createSession()");

	    if (this.connectionAttempt == 1) {
		this.setState({
		    connectionState: 'createSession()'
		});
	    }
	    else {
		this.setState({
		    connectionState: 'createSession() #' + this.connectionAttempt
		});
	    }
	    
	    return this.connection.createSession({"foo":"bar"});
	}.bind(this)).then(function(mySession) {
	    this.setState({
		connectionState: 'attachPlugin(janus.plugin.streaming)'
	    });
	    this.session = mySession;
	    return this.session.attachPlugin('janus.plugin.streaming');
	}.bind(this)).then(function(plugin) {
	    this.streamingplugin = plugin;
	    return this.streamingplugin.list();
	}.bind(this)).then(function(list) {
	    this.setState({streams:list._response.plugindata.data})    
	    
	    this.setState({
		connectionState: 'watch()'
	    });

	    console.log("LEGACY");
	    // legacy comaptibility
	    var list = this.state.streams.list
	    var validdefaultstream = false
	    for (var i=0;i<list.length;i++) {
		var id = list[i].id;
		if (id<20) {
		    validdefaultstream = true;
		    console.log("Valid Default Stream: " + id);
		}
	    }
	    if (!validdefaultstream) {
		this.setState({camerastream:list[list.length-1].id});
		console.log("NO Valid Default Stream - using " + list[list.length-1].id);
	    }

	    console.log("-----------------------------------------");
	    console.log(this.streamingplugin);
	    console.log("-----------------------------------------");
	    console.log(this.state.camerastream);
	    console.log("-----------------------------------------");
	    this.streamingplugin.watch(this.state.camerastream).then(function(response) {
		console.log("-----------------------------------------");
		console.log("MOIN");
		console.log("-----------------------------------------");
		
		pc = this.streamingplugin.getPeerConnection();
		this.myPeerConnection = pc;

		try {
		pc.oniceconnectionstatechange = function(event) {
		    console.log("ICE connection state changed ! ("+pc.iceConnectionState+")");
		    console.log(JSON.stringify(event));
		    console.log(event);
		    console.log("STATE target: " + event.target.connectionState);
		    console.log("STATE current target: " + event.currentTarget.connectionState);
		    console.log("PC: " + pc.iceConnectionState);
		    console.log("PC conn: " + pc.connectionState);
		    if (pc.iceConnectionState === "connected" || pc.iceConnectionState === "completed") {
			this.showConnectionState("connected");
			this.setState({
			    connectionState: 'ICE: connected',
			    cancelConnect : false
			});
			
			this.connectionAttempt = 1;
		    }
		    else if (pc.iceConnectionState === "disconnected") {
			this.setState({
			    connectionState: 'ICE: disconnected'
			});
			// NOTE: dissconect can recover to connect - so ignore the event.
			// see https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState

			// Woraround for bug in new chrome versions:
			setTimeout(() => {
			    if (pc.iceConnectionState === 'disconnected') {
				this.showConnectionState("disconnected");
				this.setState({
				    connectionState: 'ICE: failed'
				});

				this.setState({
				    disconnected: true,
				    receivingvideo: false
				});
				
				pc.close();
				pc = null;
				this.cleanupAndReconnect();
			    }
			}, 10000)
			
		    }
		    else if (pc.iceConnectionState === "closed") {
			console.log("Triggered: " + event.timeStamp);
			if ((event.timeStamp-this.last_close_trigger)<200) {
			    // this event is triggered twice.
			    // If pressing 'disconnect' it only triggers once.
			    // If inadvertently disconnnected it triggers twice
			    // why? no idea. but let's use it.
			    this.cleanupAndReconnect();
			}
			else {
			    this.showConnectionState("disconnected");
			    this.setState({
				connectionState: 'ICE: closed'
			    });

			    this.setState({
				disconnected: true,
				receivingvideo: false
			    });


			    // workaround: as the 'closed' event triggers twice, wait with cleanup.
				setTimeout(function(){
				    pc.close();
				    pc = null;
				}.bind(this),500);

			    
			}
			this.last_close_trigger = event.timeStamp;
		    }
		    else if (pc.iceConnectionState === "failed") {
			// in new chrome versions this does not happen anymore, although it should
			// see https://bugs.chromium.org/p/chromium/issues/detail?id=982793&q=iceconnectionstate&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Component%20Status%20Owner%20Summary%20OS%20Modified
			this.showConnectionState("disconnected");
			this.setState({
			    connectionState: 'ICE: failed'
			});

			this.setState({
			    disconnected: true,
			    receivingvideo: false
			});
			
			pc.close();
			pc = null;
			this.cleanupAndReconnect();
		    }

		    else {
			console.log("PC STATE UNHANDLED: " + pc.iceConnectionState);
		    }
		    			

		}.bind(this);
		}
		catch {
		    console.log("OUTER CATCH 1");
		}

		var dataChannelOptions = {
		    ordered: true
		};
		this.sendChannel = pc.createDataChannel("skydronedata", dataChannelOptions);
		this.sendChannel.onopen = this.handleSendChannelOpen;
		this.sendChannel.onclose = this.handleSendChannelClose;
		this.sendChannel.onmessage = this.handleSendChannelMessage;
		this.sendChannel.onerror = this.handleSendChannelError;
		this.sendChannel.onbufferedamountlow = this.handleSendChannelLowBuffer;
		//console.log("Datachannel reliable?: " + sendChannel.reliable);

		this.setState({
		    receivingvideo: true
		});
		this.videoRef.current.srcObject = pc.getRemoteStreams()[0]; //TODO fixme
	    }.bind(this)).catch(function(e) {
		// watch FAILED
		console.log(e);
		console.log(e.message);
		console.log("RECONNECT! 01-fixed");
		this.setState({
		    disconnected: true,
		    receivingvideo: false
		});
		
		//throw "b0b0 err1";
		this.cleanupAndReconnect();
	    }.bind(this));
	    this.streamingplugin.on('message', function(message) { console.log("plugin msg: " + JSON.stringify(message));});
	}.bind(this)).catch(function(e) {
	    // Standard timeout during createSession if the device is not online -> reconnect
	    // error will be Janus Client error - timeout in the 'normal' case
	    console.log("Error: " + e.message);
	    this.setState({
		disconnected: true,
		receivingvideo: false
	    });

	    this.cleanupAndReconnect();
	}.bind(this));
    }

    disconnect() {
	console.log("disconnecting...");
	if (typeof this.session != 'undefined') {
	    if (this.session!=null)  {
		this.session.cleanup();
	    } 
	}
	this.setState({
	    receivingvideo: false,
	    disconnected: true,
	    connectionState: 'disconnected'
	});
	console.log(this.session);
    }

    cancelConnect() {
	console.log("canceling (re-)connection process");
	this.setState({
	    cancelConnect: true
	})
    }
    
    handleSendChannelOpen(event) {
	console.log("DataChannel - OPEN" + JSON.stringify(event));
    }
    
    handleSendChannelClose(event) {
	console.log("DataChannel - CLOSED" + JSON.stringify(event));
    }

    handleSendChannelMessage = msg => {
	//console.log("DC msg: " + msg.data);
	// only send if state is OPEN https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
	if (this.datawebsocket.readyState===1) {
	    this.datawebsocket.send(msg.data);
	}

	// currently only allow MAVLink in browser for yuneec firmware
	if (this.props.data.firmware.flavor==='yuneec') {
	    this.myMAV.parse(Buffer.from(msg.data,'base64'));
	}
    };

    handleSendChannelError(msg) {
	console.log("DC ERROR: " + msg.data);
    }

    handleSendChannelLowBuffer(msg) {
	// ignore - this is good :)
	//console.log("DC LOW BUFFER: " + JSON.stringify(msg));
    }

    deviceControlConnection() {
	var requestUrl = SigV4Utils.getSignedUrl('wss', 'ae41f5w1njy9h-ats.iot.us-west-2.amazonaws.com', '/mqtt', 'iotdevicegateway', 'us-west-2',AWS.config.credentials.accessKeyId, AWS.config.credentials.secretAccessKey, AWS.config.credentials.sessionToken);
	console.log(requestUrl);

	var clientId = Math.random().toString(36).substring(7);
	this.mqttclient = new Paho.MQTT.Client(requestUrl, clientId);
	var connectOptions = {
	    onSuccess: function () {
		console.log('connected control connection');
	    }.bind(this),
	    useSSL: true,
	    timeout: 15,
	    mqttVersion: 4,
	    onFailure: function (err) {
		console.error('connect failed' + err.errorMessage);
	    }
	};
	this.mqttclient.connect(connectOptions);
	this.mqttclient.onConnectionLost = (res) => {
	    console.log(res)
	    console.log("onConnectionLost:"+res.errorMessage);
	}
    }


    
    controlBandwidth(min,max) {
	// b0b0: this should use the same MQTT connection as Janus
	
	console.log("Controlling bandwidth - min: " + min + " max: " +  max);
	if (this.mqttclient != null) {
	    var message = new Paho.MQTT.Message(JSON.stringify({"minBitrate" : min, "maxBitrate" : max}));
	    message.destinationName = "cc/" + this.props.device + "/control/"; // FIXME get correct device by refactoring to have devicecomponent
	    this.mqttclient.send(message);
	}

    }

    send_heartbeat() {
	if (	this.myPeerConnection) {
	    
	if (this.myPeerConnection.iceConnectionState === "connected") {
	    //console.log("send_heartbeat()");
	    if (this.myMAV) {
		this.myMAV.createMessage("HEARTBEAT",
					 {
					     'type': 6, //MAV_TYPE_GCS
					     'autopilot': 8, //MAV_AUTOPILOT_INVALID
					     'base_mode': 0,
					     'custom_mode': 0,
					     'system_status': 0,
					     'mavlink_version': 2
					 },
					 function(msg) {
					     this.mavToDatachannel(msg.buffer)
					 }.bind(this));
	    }

	    /*
	      if (this.myMAV) {
	      this.myMAV.createMessage("COMMAND_LONG",
	      {
	      'target_system': 1,
	      'target_component': 100, //camera 100
	      'command': 511,
	      'confirmation': 0,
	      'param1': 263,
	      'param2': 100000,
	      'param3': 0,
	      'param4': 0,
	      'param5': 0,
	      'param6': 0,
	      'param7': 0,
	      },
	      function(msg) {
	      console.log(msg);
	      this.mavToDatachannel(msg.buffer)
	      }.bind(this));
	      }
	    */
	}
	}
}


    cmd_hold() {
	if (this.myMAV) {
	    this.myMAV.createMessage("COMMAND_LONG",
				     {
					 'target_system': 1,
					 'target_component': 1,
					 'command': 176, // https://mavlink.io/en/messages/common.html#MAV_CMD_DO_SET_MODE
					 'confirmation': 0,
					 'param1': 220,
					 'param2': 4, // custom main mode AUTO
					 'param3': 3, // custom sub mode  LOITER https://github.com/Dronecode/DronecodeSDK/blob/23b76bcd208ce12159e9bd089451ff7c04e284ab/core/px4_custom_mode.h#L50-L59
					 'param4': 0,
					 'param5': 0,
					 'param6': 0,
					 'param7': 0
				     },
				     function(msg) {
					 console.log(msg);
					 this.mavToDatachannel(msg.buffer)
				     }.bind(this));
	}

    }

    
    cmd_startmission() {
	if (this.myMAV) {
	    this.myMAV.createMessage("COMMAND_LONG",
				     {
					 'target_system': 1,
					 'target_component': 1,
					 'command': 300, // https://mavlink.io/en/messages/common.html#MAV_CMD_MISSION_START
					 'confirmation': 0,
					 'param1': 0,
					 'param2': 2,
					 'param3': 0,
					 'param4': 0,
					 'param5': 0,
					 'param6': 0,
					 'param7': 0
				     },
				     function(msg) {
					 console.log(msg);
					 this.mavToDatachannel(msg.buffer)
				     }.bind(this));
	}

    }

    cmd_rtl() {
	if (this.myMAV) {
	    this.myMAV.createMessage("COMMAND_LONG",
				     {
					 'target_system': 1,
					 'target_component': 1,
					 'command': 20, // https://mavlink.io/en/messages/common.html#MAV_CMD_NAV_RETURN_TO_LAUNCH
					 'confirmation': 0,
					 'param1': 0,
					 'param2': 0,
					 'param3': 0,
					 'param4': 0,
					 'param5': 0,
					 'param6': 0,
					 'param7': 0,
				     },
				     function(msg) {
					 console.log(msg);
					 this.mavToDatachannel(msg.buffer)
				     }.bind(this));
	}

    }

    trigger_camera() {
	console.log("trigger_camera()");
	if (this.myMAV) {
	    this.myMAV.createMessage("COMMAND_LONG",
				     {
					 'target_system': 1,
					 'target_component': 100, //camera 100
					 'command': 2000, // https://mavlink.io/en/messages/common.html#MAV_CMD_IMAGE_START_CAPTURE
					 'confirmation': 0,
					 'param1': 0,
					 'param2': 0,
					 'param3': 1, //single image
					 'param4': 0,
					 'param5': 0,
					 'param6': 0,
					 'param7': 0,
				     },
				     function(msg) {
					 console.log(msg);
					 this.mavToDatachannel(msg.buffer)
				     }.bind(this));
	}

    }

    reset_gimbal() {
	console.log("reset gimbal");
	this.setState({
	    gimbal_global_x: 0,
	    gimbal_global_y: 0
	});
    }

    cmd_gimbal_control2(direction,type,e) {
	e.stopPropagation()
	e.preventDefault();
	console.log("GIMBAL");
	if (type==1) {
	    console.log("button pressed");
	    if (direction==='up') {
		this.setState({
		    gimbal_web_control_y_axis: -0.5,
		    gimbal_control_image: "icons/gimbal_control_up.png"
		});
	    }
	    else if (direction==='down') {
		this.setState({
		    gimbal_web_control_y_axis: 0.5,
		    gimbal_control_image: "icons/gimbal_control_down.png"
		});
	    }

	    else if (direction==='left') {
		this.setState({
		    gimbal_web_control_x_axis: -0.5,
		    gimbal_control_image: "icons/gimbal_control_left.png"
		});
	    }

	    else if (direction==='right') {
		this.setState({
		    gimbal_web_control_x_axis: 0.5,
		    gimbal_control_image: "icons/gimbal_control_right.png"
		});
	    }

	}
	else {
	    console.log("button released");
	    this.setState({
		gimbal_web_control_x_axis: 0,
		gimbal_web_control_y_axis: 0,
		gimbal_control_image: "icons/gimbal_control.png"
	    });
	}
	console.log(direction);
    }
    cmd_switch_stream(stream) {
	this.streamingplugin.switch(stream);
    }
    
    cmd_gimbal_control(pitch,yaw) {
	//console.log("GIMBAL CONT");
	if (this.myMAV) {
	    this.myMAV.createMessage("COMMAND_LONG",
				{
				    'target_system': 1,
				    'target_component': 1,
				    'command': 205, // https://mavlink.io/en/messages/common.html#MAV_CMD_DO_MOUNT_CONTROL
				    'confirmation': 0,
				    'param1': pitch,
				    'param2': 0,
				    'param3': yaw,
				    'param4': 0,
				    'param5': 0,
				    'param6': 0,
				    'param7': 2,
				},
				function(msg) {
				    //console.log("gimbal " + pitch + " " + yaw);
				    //console.log(msg);
				    this.mavToDatachannel(msg.buffer)
				}.bind(this));
	}
    }

    mavToDatachannel(rawmavlink) {
		// currently only allow MAVLink in browser for yuneec firmware
	if (this.props.data.firmware.flavor==='yuneec') {

	//console.log("sending MAVLink data via datachannel: " + rawmavlink.length);
	//console.log(rawmavlink.toString('hex'));
	var sendQueue = [];
	
	function chunkArray(myArray, chunk_size){
	    var index = 0;
	    var arrayLength = myArray.length;
	    var tempArray = [];
	    
	    for (index = 0; index < arrayLength; index += chunk_size) {
		var myChunk = myArray.slice(index, index+chunk_size);
		tempArray.push(myChunk);
	    }

	    return tempArray;
	}

	var msgarray = chunkArray(new Buffer(rawmavlink),16384);
	for (var i=0;i<msgarray.length
	     ;i++) {
	    sendQueue.push(msgarray[i]);
	}
	sendQueue.forEach((queuedmsg) => {
	    if (typeof this.sendChannel  !== 'undefined' && this.sendChannel != null) {
		var readyState = this.sendChannel.readyState;
		if (readyState == "open") {
		    this.sendChannel.send(new Buffer(queuedmsg,'binary').toString("base64"))
		}
	    }
	}
			 );
	}
	else {
	    //console.log("MAVLink command rejected - device firmware (" + this.props.data.firmware.flavor + ") not supported.");
	    // FIXME add this to some debug logger
	}
    }

    setGamePad(y,x,button0_state) {
	var THRESHOLD=0.2; // gamepad is wobbiling around - this is not a real controll change
	if (x<(0-THRESHOLD) || x>(0+THRESHOLD)) {
	    this.setState({
		gimbal_control_x_axis: x
	    });
	}
	else if (this.state.gimbal_control_x_axis!=0) {
	    this.setState({
		gimbal_control_x_axis: 0
	    });
	}
	
	if (y<(0-THRESHOLD) || y>(0+THRESHOLD)) {
	    this.setState({
		gimbal_control_y_axis: y
	    });
	}
	else if (this.state.gimbal_control_y_axis!=0) {
	    this.setState({
		gimbal_control_y_axis: 0
	    });
	}
	this.setState({
	    gamepad_button0_pressed: button0_state
	});
	//console.log("Gamepad: x=" + x +" y=" + y);
    }
   
    render() {
	if (this.props.data) {
	//console.log(this.state.bandwidth);
	var hiddenStyle ={};
	if (this.props.hidden) {
	    hiddenStyle = {
		//visibility: 'hidden',
		//TODO remove padding from root component that leads to empty space
		display: 'none'
	    }
	}

	var mqtt_state;
	if (this.props.data.connected) {
	    mqtt_state = 'connected';
	}
	else {
	    mqtt_state = 'disconnected';
	}


	    // Possible states we are in:
	    // 1. disconnected
	    // 2. receiving video
	    // 3. connecting / canceling

	    var unit = 'K';
	    var distance_display = '';
	    if (this.state.gcs_lat!=0 && this.state.gcs_lon!=0 && this.state.device_lat !=0 && this.state.device_lon!=0) {
		var distance =  this.distance(this.state.gcs_lat,this.state.gcs_lon,this.state.device_lat,this.state.device_lon,unit);
		if (unit==='K') {
		    if (distance < 1) {
			distance_display = Math.floor(distance*1000)+'m';
		    }
		    else {
			distance_display = Math.floor(distance)+'km';
		    }
		}
	    }


	    var realtimemap = <RealtimeMap lat={this.state.device_lat} lon={this.state.device_lon} alt={this.state.device_alt}/>;
	    // FIXME - hide map
	    if (true) {
		realtimemap = <div/>
	    }

	    //		"signalQuality": msg.SignalQuality,
	    //"accessTechnologies": msg.AccessTechnologies

	    var statusbar = <StatusBar pc={this.myPeerConnection} alt={this.state.device_alt} bat={this.state.device_bat} dist={distance_display} signal={this.state.signalQuality} at={this.state.accessTechnologies} mqttstate={this.props.data.connected} icedisconnected={this.state.disconnected} receivingvideo={this.state.receivingvideo} cancel={this.state.cancelConnect} firmware={this.props.data.firmware}/>;

	    var actionbar = <ActionBar connect={() => {this.connect(this.props.device)}} disconnect={() => {this.disconnect()}} cancelConnect={() => {this.cancelConnect()}} mqttstate={this.props.data.connected} icedisconnected={this.state.disconnected} receivingvideo={this.state.receivingvideo} cancel={this.state.cancelConnect}/>
		
	    
	if (this.state.disconnected) {
	    const props = {
		setGimbalState: this.setGamePad.bind(this)
	    }

	    	    var hide = {
		display:'none'
	    }


			//<DeviceConfig mqttclient={this.mqttclient}/>
			//Access:    {accessTechString}<br/>
			//Signal: {this.state.signalQuality}<br/>


	    return (
		    <div style={hiddenStyle}>
		    {statusbar}
		{actionbar}

		    {realtimemap}
		    <div style={hide}>
		    <GamePad props={props}/>
		    </div>
		</div>
		    
	    );
	}
	else if (this.state.receivingvideo) {
	    const style = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		border: "solid 1px #ddd",
		background: "#f0f0f0"
	    };




//		    Width: {this.state.width}
//		Height: {this.state.height}
//		    {this.props.device} <button onClick={() => {this.disconnect()}}>discconnect</button>{this.state.connectionState}

	    const functions = {
		reset_gimbal: this.reset_gimbal.bind(this),
		trigger_camera: this.trigger_camera.bind(this),
		cmd_rtl: this.cmd_rtl.bind(this),
		cmd_startmission: this.cmd_startmission.bind(this),
		cmd_hold: this.cmd_hold.bind(this),
		cmd_gimbal_control2: this.cmd_gimbal_control2.bind(this),
		streamingpluginSwitch: this.cmd_switch_stream.bind(this),
		controlBandwidth: this.controlBandwidth.bind(this)
	    }
	    
	    //<DeviceConfig mqttclient={this.mqttclient}/>
	    //Distance to UAV: {distance_display}<br/>
	    //Access:    {accessTechString}<br/>
	    //Signal: {this.state.signalQuality}<br/>
	    //<WebRtcStats pc={this.myPeerConnection} />
	    //<button onClick={() => {this.disconnect()}}>discconnect</button>

	    var streams = this.state.streams;
	    return (
		    <div style={hiddenStyle}>
		    {statusbar}
		{actionbar}

		    {realtimemap}
		    <video poster="./icons/video_wait_poster.png" width="100%" height="100%" ref={this.videoRef} autoPlay/>
		    
		    <DeviceControl data={this.props.data} streams={streams} functions={functions} />
		    

		</div>
		    
		    
	    );

	    //		    <MenuItem value={10}>E90 (transcoded)</MenuItem> 
		// <MenuItem value={11}>E90 (passthrough)</MenuItem>

	    // 		    Images: {this.state.images_captured}
	}

	else {
	    const props = {
		setGimbalState: this.setGamePad.bind(this)
	    }

	    var hide = {
		display:'none'
	    }

	    var cancelbutton = <div></div>;
	    var posterurl = "./icons/video_wait_poster.png";
	    if (!this.state.cancelConnect) {
		cancelbutton =  <button onClick={() => {this.cancelConnect()}}>cancel</button>;
	    }
	    else {
		posterurl = "./icons/video_wait_poster.png"
	    }

	    	//<DeviceConfig mqttclient={this.mqttclient}/>
		//Access:    {accessTechString}<br/>
		//Signal: {this.state.signalQuality}<br/>
		//{cancelbutton}
	    
	    return (
		    <div style={hiddenStyle}>
		    {statusbar}
		{actionbar}
		
		    {realtimemap}
		    <video poster={posterurl} width="100%" height="100%" ref={this.videoRef} autoPlay/>
		    <div style={hide}>
		    <GamePad props={props}/>
		    </div>
		    </div>
		    
	    );
	}
	}
	else {
	    return null;
	}
    }
	

}
