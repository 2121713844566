/*global SigV4Utils*/
/*global Paho*/
import React, { Component } from "react";
import AWS from 'aws-sdk';
import Device from './Device.js';
import ClaimDevice from './ClaimDevice.js';
import UnClaimDevice from './UnClaimDevice.js';
import Map from './Map.js';
import "react-tabs/style/react-tabs.css";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});


class DeviceList extends Component {
    constructor(props) {
	super(props);
	this.doDetails();
	this.listenForPresenceChanges();
	this.devicedata = [];
	
	this.state = {
	    value: 0,
	    devices: [],
	    deviceDetails: [],
	    deviceStateDevices: [],
	    deviceStateStates: [],
	    deviceStateTimestamps: [],
	    claimDeviceMode: false
	}
    }

    handleChange = (event, value) => {
	this.setState({ value });
    };


    getDeviceData(device) {
	for (var i=0;i<this.devicedata.length;i++) {
	    if (this.devicedata[i].device===device) {
		return this.devicedata[i];
	    }
	}
	return null;
    }
    

    listenForPresenceChanges() {
	//$aws/events/presence/#
	var requestUrl = SigV4Utils.getSignedUrl('wss', 'ae41f5w1njy9h-ats.iot.us-west-2.amazonaws.com', '/mqtt', 'iotdevicegateway', 'us-west-2',AWS.config.credentials.accessKeyId, AWS.config.credentials.secretAccessKey, AWS.config.credentials.sessionToken);
	console.log(requestUrl);

	var clientId = Math.random().toString(36).substring(7);
	var client = new Paho.MQTT.Client(requestUrl, clientId);
	var connectOptions = {
	    onSuccess: function () {
		console.log('connected');
		for (var i=0;i<this.state.devices.length;i++) {
		    client.subscribe("cc/"+this.state.devices[i]+ "/presence");
		    client.subscribe("cc/"+this.state.devices[i]+ "/shadow");
		    client.subscribe("cc/"+this.state.devices[i]+ "/events/"); // NOTE: be careful with topic names with and without trailing slash!
		}
	    }.bind(this),
	    useSSL: true,
	    timeout: 15,
	    mqttVersion: 4,
	    onFailure: function (err) {
		console.error('connect failed' + err.errorMessage);
	    }
	};

	client.onMessageArrived = onMessageArrived.bind(this);
	
	client.connect(connectOptions);
	client.onConnectionLost = (res) => {
	    console.log("Connection Lost"); // WHY? RECONNECT !?
	}
	    

	function onMessageArrived(message) {
	    console.log("onMessageArrived:"+message.payloadString);
	    var msg = JSON.parse(message.payloadString);
	    var topic = message.destinationName.substring(68);
	    console.log("TOPIC: " + topic);
	    if (topic=="events/") {
		this.props.props.addNotification(msg.event.type,msg.event.key,"info");
	    }
	    
	    var connected = false;
	    if (message.destinationName.substring(68)=='presence') {
		connected = true;
		this.updateDeviceState(msg.clientId,connected,msg.timestamp);

		var currentdevices = this.state.deviceDetails;
		for (var i=0; i<currentdevices.length; i++) {
		    console.log(currentdevices[i].device);
		    if (currentdevices[i].device==msg.clientId) {
			if (msg.eventType=='connected') {
			    currentdevices[i].connected = true;
			}
			else if (msg.eventType=='disconnected') {
			    currentdevices[i].connected = false;
			}
		    }
		}
		this.setState({
		    'deviceDetails': currentdevices
		});

	    }
	    else if (message.destinationName.substring(68)=='shadow') {
		//else if (msg.current.state.reported!=undefined) {
		var currentdevices = this.state.deviceDetails;
		for (var i=0; i<currentdevices.length; i++) {
		    console.log(currentdevices[i].device);
		    if (currentdevices[i].device==message.destinationName.substring(3,67)) {
			// update device
			currentdevices[i].location.external.x = msg.current.state.reported.location.external.x;
			currentdevices[i].location.external.y = msg.current.state.reported.location.external.y;
			currentdevices[i].location.external.z = msg.current.state.reported.location.external.z;
		    }
		}
		this.setState({
		    'deviceDetails': currentdevices
		});

	    }

	}
	
	// TODO subscribe to presence topic for all deviceid's
	// TODO listen for state change and render update
    }

    updateDeviceState(device, mystate,stamp) {
	console.log("EXEC");
	let devicesCopy = JSON.parse(JSON.stringify(this.state.deviceStateDevices))
	let statesCopy = JSON.parse(JSON.stringify(this.state.deviceStateStates))
	let timestampsCopy = JSON.parse(JSON.stringify(this.state.deviceStateTimestamps))

	// check if device is already in state array
	var updated = false;
	for (var i=0; i<devicesCopy.length;i++) {
	    if (devicesCopy[i]===device) {
		// only update if newer message
		if (timestampsCopy[i]<stamp) {
		    statesCopy[i]=mystate;
		    timestampsCopy[i]=stamp;
		}
		updated = true;
	    }
	}
	if (!updated) {
	    // otherwise add it
	    devicesCopy.push(device);
	    statesCopy.push(mystate);
	    timestampsCopy.push(stamp);
	}
	
	this.setState({
	    deviceStateDevices: devicesCopy,
	    deviceStateStates: statesCopy,
	    deviceStateTimestamps: timestampsCopy
	});
    }


    doDetails() {
	//b0b0 FIXME: instead of using the state vars in app for credentials we are using the implicit credentials in AWS - not clean implementation
	
	AWS.config.update({region:'us-west-2'});
	var lambda = new AWS.Lambda();
	var payload = {
	    "userid" : AWS.config.credentials.identityId
	};
	var params = {
	    FunctionName: 'skydronetest04-getPairedDevicesDetails-1214FO9W1YGLL',
	    Payload: JSON.stringify(payload)
	};
	lambda.invoke(params, function(err, data) {
	    if (err)  {
		console.log(err, err.stack);
		//TODO: notify user with dialog
	    }
	    else {
		console.log(data);
		var payload = JSON.parse(data.Payload);
		this.devicedata = payload;
		console.log(payload);
		var devices = [];
		var deviceDetails = [];
		for (var i=0;i<payload.length;i++) {
		    devices.push(payload[i].device);
		    deviceDetails.push(payload[i]);
		    this.updateDeviceState(payload[i].device,payload[i].connected,0);
		}
		this.setState({
		    'devices': devices,
		    'deviceDetails': deviceDetails
		});
	    }
	}.bind(this));

    }

    removeDevice(deviceid) {
	
    }
    
    setClaimDeviceMode(state) {
	this.setState({claimDeviceMode: state});
	this.doDetails();
    }
    
    render() {
	const { classes } = this.props;
	const { value } = this.state;

	function decorateTab(device,state,data) {
	    if (data) {
		var state_var;
		if (state) {
		    state_var = 'connected';
		}
		else {
		    state_var = 'disconnected';
		}
		//icon={<PhoneIcon />}
		var devicename = device.substring(0,16);
		if (data.name.length>0) {
		    devicename = data.name;
		}
		return <Tab key={device} value={device} label={devicename} ></Tab>
	    }
	    else {
		return null;
	    }
	}
	function decorateTabContainer(device,state,data) {
	    var state_var;
	    if (state) {
		state_var = 'connected';
	    }
	    else {
		state_var = 'disconnected';
	    }
	    return <TabContainer key={device}><Device hidden={value!=device} device={device} data={data} /></TabContainer>
	}

	var listItems = [] ;
	var tabs = [];
	var tabContainers = [];
	for (var i=0; i<this.state.devices.length;i++) {
	    var match = false;
	    for (var j=0; j<this.state.deviceStateDevices.length;j++) {
		if (this.state.devices[i]===this.state.deviceStateDevices[j]) {
		    tabs.push(decorateTab(this.state.deviceStateDevices[j],this.state.deviceStateStates[j],this.getDeviceData(this.state.deviceStateDevices[j])));
		    match = true;
		    tabContainers.push(decorateTabContainer(this.state.deviceStateDevices[j],this.state.deviceStateStates[j],this.getDeviceData(this.state.deviceStateDevices[j])));

		}
	    }
	    if (!match) {
		tabs.push(decorateTab(this.state.devices[i],'unknown state'));

		tabContainers.push(decorateTabContainer(this.state.devices[i],'unknown state',this.getDeviceData(this.state.devices[i])));
	    }
	}



	if (value!='map') {
	    hiddenStyle = {
		display: 'none'
	    }
	}
	else {
	    hiddenStyle = {}
	}
	//tabs.push(<Tab value='map' key='map' label='fleet'></Tab>);
	//tabContainers.push(<TabContainer key='map'><div style={hiddenStyle}><Map deviceDetails={this.state.deviceDetails}/></div></TabContainer>);



	
	var claimdevice;
	var unclaimdevice;
	
	const claimdeviceprops = {
	    setClaimDeviceMode: this.setClaimDeviceMode.bind(this)
	}
	
	claimdevice = <ClaimDevice props={claimdeviceprops}/>;

	const unclaimdeviceprops = {
	    setClaimDeviceMode: this.setClaimDeviceMode.bind(this),
	    removeDevice: this.removeDevice.bind(this),
	    devices: this.state.devices
	}

	unclaimdevice = <div></div>;
	if (this.state.devices.length>0) {
	    unclaimdevice = <UnClaimDevice props={unclaimdeviceprops}/>;
	}

	tabs.push(<Tab value='claim' key='claim' label='claim device' ></Tab>);
	//value!=device

	var hiddenStyle ={};
	if (value!='claim') {
	    hiddenStyle = {
		display: 'none'
	    }
	}
	else {
	    hiddenStyle = {}
	}

	tabContainers.push(<TabContainer key="claim"><div style={hiddenStyle}>{claimdevice}<br/>{unclaimdevice}</div></TabContainer>);




	
	
	return (
		<div>
	    	<div className={classes.root}>
	    	<AppBar position="static" color="default">
	    	<Tabs
            value="map"
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
	    forcerendertabpanel="true"
	    	>
		{tabs}
	    </Tabs>

	    	</AppBar>
		{tabContainers}
	    </div>


		</div>
		
	);
    }

}

export default withStyles(styles)(DeviceList);
