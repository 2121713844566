import React, { Component } from "react";
import AWS from 'aws-sdk';

class MissionList extends Component {

    componentDidMount() {
	fetch('https://api.skydrone.aero/core-dev/missions',
	      { headers:
		{
		    Authorization: 'Bearer ' + AWS.config.jwt
		}
	      })
        .then(res => res.json())
        .then((data) => {
            this.setState({ contacts: data })
	    console.log(data);
        })
        .catch(console.log)
    }
    
    render() {
	return <div>moin</div>;
    }
}


export default (MissionList);

