import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Grid from '@material-ui/core/Grid';
import "./Login.css";
import 'amazon-cognito-js';
import AWS from 'aws-sdk';
import SignUp from "./SignUp.js";
import ConfirmEmail from "./ConfirmEmail.js";
import ForgotPassword from "./ForgotPassword.js";

import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails
} from 'amazon-cognito-identity-js';

export default class Login extends Component {
    constructor(props) {
	super(props);

	this.state = {
	    email: "",
	    password: "",
	    restoreLoginTried: false,
	    errorMessage: "",
	    errorCode: "",
	    insignup: false,
	    inforgotpassword: false
	};

	//b0b0: FIXME use stack info
	AWS.config.region = 'us-east-1';
	this.poolData = {
  	    UserPoolId : 'us-east-1_7dojC06dm', //b0b0
	    ClientId :   '10gmq555pdji7gsoc9qpl8lajn' //b0b0
	};
	this.userPool = new CognitoUserPool(this.poolData);
	this.cognitoUser = null;

	
    }

    componentDidMount() {
	this.restoreLogin();
	setInterval(this.refreshCognito.bind(this),1200000);
    }
    
    restoreLogin() {
	var cognitoUser = this.userPool.getCurrentUser();
	
	if (cognitoUser != null) {
            cognitoUser.getSession(function(err, session) {
		if (err) {
		    //alert(err);
		    this.setState({
			restoreLoginTried: true
		    });
                    return;
		}
		console.log('session validity: ' + session.isValid());
		this.getAWSCredentials(session,null);
		//b0b0: fixme - make this a promise and only set state after I got AWS creds.
		this.setState({
		    restoreLoginTried: true
		});
		
	    }.bind(this));
	}
	else {
	    this.setState({
		restoreLoginTried: true
	    });
	    
	}

    }

    validateForm() {
	return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
	this.setState({
	    [event.target.id]: event.target.value
	});
    }

    getAWSCredentials(tokens,username) {
	AWS.config.jwt = tokens.getIdToken().getJwtToken();
	AWS.config.credentials = new AWS.CognitoIdentityCredentials({
	    IdentityPoolId: 'us-east-1:88fbf66f-d8bd-4e75-8b47-3424b4c18915',
	    Logins: {
		'cognito-idp.us-east-1.amazonaws.com/us-east-1_7dojC06dm': tokens.getIdToken().getJwtToken()
	    }
	});
	
	AWS.config.credentials.get(function(err){
	    if (err) {
		alert(err.message);
	    }
	    else {
		this.props.props.userHasAuthenticated(true);
		this.props.props.setAwsCredentials(AWS.config.credentials.identityId, AWS.config.credentials.accessKeyId, AWS.config.credentials.secretAccessKey, AWS.config.credentials.sessionToken);



		// b0b0 FIXME do this outside after get credential promise; dont pass username through get credentials (unrelated!)
		if (username != null) {
		    AWS.config.update({region:'us-east-1'});
		    var client = new AWS.CognitoSyncManager();
		    client.openOrCreateDataset('skydroneuser', function (err, dataset) {
			dataset.put('user', username, function(err, record) {
			    console.log(record);
			});
			dataset.synchronize({

			    onSuccess: function(dataset, newRecords) {
				console.log("sync success")
			    },

			    onFailure: function(err) {
				console.log(err);
				console.log("sync fail");
			    }
			    
			});
		    });
		}


		
		return;
	    }
	}.bind(this));

    }
    
    handleSubmit = event => {
	event.preventDefault();

	var authenticationData = {
	    Username : this.state.email,
	    Password : this.state.password
	};
	var authenticationDetails = new AuthenticationDetails(authenticationData);

	var userData = {
	    Username : this.state.email,
	    Pool : this.userPool
	};
	
	this.cognitoUser = new CognitoUser(userData);

	this.cognitoUser.authenticateUser(authenticationDetails, {
	    onSuccess: function (result) {
		console.log(result);
		this.getAWSCredentials(result,userData.Username);
		
	    }.bind(this),

	    onFailure: function (err) {
		console.log("login failed");
		console.log(err);
		this.setState({
		    errorMessage: err.message,
		    errorCode: err.code
		});
	    }.bind(this)
	});
    }

    setForgotPasswordState(state) {
	this.setState({inforgotpassword: state});
    }
    setSignupState(state) {
	this.setState({insignup: state});
    }

    setEmailConfirmed() {
	this.setState({
	    errorCode: "",
	    errorMessage: ""
	});
    }

    refreshCognito() {
	console.log("Attempting to refresh cognito credentials...");
	var cognitoUser = this.userPool.getCurrentUser();
	if (cognitoUser != null) {
            cognitoUser.getSession(function(err, session) {
		var refresh_token = session.getRefreshToken()
		var update_session = function () {
			cognitoUser.refreshSession(refresh_token, (err, session) => {
			if (err) {
			    console.log(err);
			}
			else {
			    AWS.config.credentials.params.Logins['cognito-idp.us-east-1.amazonaws.com/us-east-1_7dojC06dm'] = session.getIdToken().getJwtToken();
			    AWS.config.jwt = session.getIdToken().getJwtToken();
			    AWS.config.credentials.refresh((err) => {
				if (err) {
				    console.log(err);
				}
				else {
				    console.log("TOKEN SUCCESSFULLY UPDATED");
				}
			    });
			}
		    });
		}
		update_session();
		
	    });
	}

    }
    
    render() {
	if (this.state.restoreLoginTried) {
	    if (this.state.errorCode==="UserNotConfirmedException") {
		const props = {
		    cognitoUser: this.cognitoUser,
		    setEmailConfirmed: this.setEmailConfirmed.bind(this)
		}
		return(
			<ConfirmEmail props={props}/>
		);
	    }
	    else if (this.state.inforgotpassword) {
		const props = {
		    setForgotPasswordState: this.setForgotPasswordState.bind(this)
		};

		return(
			<ForgotPassword props={props}/>
		);
		
	    }
	    else if (this.state.insignup) {
		const props = {
		    setSignupState: this.setSignupState.bind(this)
		};

		return(
			<SignUp props={props}/>
		);
	    }
	    else {
		return (
		    <div className="Login">
			<center>
			    <img width="240" src="icons/droniq_logo.png"/>
			    <br/>
			    <br/>
			    </center>
		    <form onSubmit={this.handleSubmit}>
			<Grid container spacing={1}>
			    <Grid container direction={'column'} item xs={12} spacing={3}>
				<FormGroup controlId="email" bssize="large">
				    <Grid container direction={'column'} item xs={12} spacing={3}>
					<FormLabel>Email</FormLabel>
				    </Grid>
				    <Grid container direction={'column'} item xs={12} spacing={3}>
					<FormControl
					    autoFocus
					    type="email"
					    value={this.state.email}
					    onChange={this.handleChange}
					/>
				    </Grid>
				</FormGroup>
			    </Grid>
			    <Grid container direction={'column'}  item xs={12} spacing={3}>
				<FormGroup controlId="password" bssize="large">
				    <Grid container direction={'column'} item xs={12} spacing={3}>
					<FormLabel>Password</FormLabel>
				    </Grid>
				    <Grid container direction={'column'} item xs={12} spacing={3}>
					<FormControl
					    value={this.state.password}
					    onChange={this.handleChange}
					    type="password"
					/>
				    </Grid>
				</FormGroup>
			    </Grid>
			</Grid>

			    
			<font color="red">{this.state.errorMessage}</font><br/>
			<Grid container justify="center" direction="row">
			    <Grid item xs={33}>
				<Button
				    block
				    bssize="large"
				    disabled={!this.validateForm()}
				    type="submit"
				>
				    Login
				</Button>
			    </Grid>
			    <Grid item xs={33}>
				<Button onClick={() => {this.setSignupState(true)}}>create account</Button>
			    </Grid>
			    <Grid item xs={33}>
				<Button onClick={() => {this.setForgotPasswordState(true)}}>forgot password</Button>
			    </Grid>
			</Grid>
		    </form>

		    </div>
		);
	    }
	}
	else {
	    return (
		    <div className="Login">
		    </div>
	    );
	}
    }
}
